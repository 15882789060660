import { Injectable } from '@angular/core';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { environment } from '@environment/environment';
import {
  AppInsightsService,
  EventType,
  IEventTelemetry,
  ITraceTelemetry,
  SeverityLevel,
} from './app-insights.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseComponent } from '@standalone/base-component.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class LoggingService extends BaseComponent {
  constructor(
    private appInsightsService: AppInsightsService,
    private gtmService: GoogleTagManagerService
  ) {
    super();
    if (environment.logging.consoleEnabled) {
      console.log('Console logging enabled!');
    }
  }

  public log(
    message: string,
    severityLevel?: SeverityLevel,
    properties?: ICustomProperties | any
  ): void {
    const logObject = { message, properties };
    this.logConsole(logObject);

    if (!environment.logging.enabled) {
      return;
    }
    const traceTelemetry = {
      message: message,
      severityLevel: severityLevel,
      properties: properties
        ? JSON.parse(JSON.stringify(properties))
        : properties,
    } as ITraceTelemetry;
    this.appInsightsService.logTrace(traceTelemetry);
  }

  public logException(error: Error | HttpErrorResponse): void {
    if (environment.logging.consoleEnabled) {
      console.error(error);
    }
    if (environment.logging.enabled) {
      this.appInsightsService.logException({ exception: error });
    }
  }

  private logConsole(obj: any) {
    if (environment.logging.consoleEnabled) {
      console.log(obj);
    }
  }

  public async logEvent(
    eventName: string,
    eventType: EventType,
    properties?: any
  ): Promise<void> {
    const eventTelemetry = {
      name: eventName,
      type: eventType,
      properties: properties
        ? JSON.parse(JSON.stringify(properties))
        : properties,
    } as IEventTelemetry;

    this.logConsole(eventTelemetry);
    if (eventType === EventType.conversion) {
      const gtmTag = {
        event: eventName,
        ...properties,
      };

      try {
        await this.gtmService.pushTag(gtmTag);
        if (eventName === 'purchase complete') {
          var pjpixel: any; // pjpixel is loaded via gtm
          pjpixel?.track(
            'purchase',
            { amount: properties['orderAmount'], currency: 'SEK' },
            { eventId: properties['transaction_id'] }
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          this.logException(error);
        }
      }
    }

    if (environment.logging.enabled) {
      this.appInsightsService.logEvent(eventTelemetry);
    }
  }
}

export {
  IEventTelemetry,
  ITraceTelemetry,
  EventType,
  ICustomProperties,
  SeverityLevel,
} from './app-insights.service';
