import { DEFAULT_CURRENCY_CODE, Inject, Injectable } from '@angular/core';
import { IBreadcrumb } from '@core/components/breadcrumb/breadcrumb.component';
import { environment } from '@environment/environment';
import { IFaq } from '@environment/settings-interface';
import { IStructuredData } from '@features/structured-data/structured-data.component';
import { BaseComponent } from '@standalone/base-component.component';
import { Observable, Subject } from 'rxjs';
import {
  WithContext,
  Product,
  ProductGroup,
  OfferShippingDetails,
  MerchantReturnPolicy,
  Offer,
  ItemAvailability,
  PriceSpecification,
} from 'schema-dts';

import {
  IShopifyProduct,
  IShopifyProductOption,
  IShopifyProductVariant,
} from 'server/interfaces/shopify-interface';
import { ICourse } from 'server/interfaces/zenler-interface';

@Injectable({
  providedIn: 'root',
})
export class StructuredDataFactoryService extends BaseComponent {
  private readonly context: 'https://schema.org' = 'https://schema.org';
  private readonly updatedSubject: Subject<IStructuredData<any>> =
    new Subject();
  private readonly removedSubject: Subject<string | number> = new Subject();
  constructor(@Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string) {
    super();
  }
  public remove(id: string | number): void {
    this.removedSubject.next(id);
  }

  public get updated$(): Observable<IStructuredData<any>> {
    return this.updatedSubject.asObservable();
  }

  public get removed$(): Observable<string | number> {
    return this.removedSubject.asObservable();
  }

  public generateForBreadcrumbs(id: string, breadcrumbs: IBreadcrumb[]): void {
    this.updatedSubject.next({
      id,
      data: {
        '@context': this.context,
        '@type': 'BreadcrumbList',
        name: 'Breadcrumbs',
        itemListElement: breadcrumbs.map((breadcrumb) => ({
          '@type': 'ListItem',
          position: breadcrumbs.indexOf(breadcrumb) + 1,
          name: breadcrumb.label,
          item: `${environment.apiTarget}${breadcrumb.url}`,
        })),
      },
    });
  }

  public generateForFaqs(id: string, faqs: IFaq[]): void {
    this.updatedSubject.next({
      id,
      data: {
        '@context': this.context,
        '@type': 'FAQPage',
        name: 'Vanliga frågor om fotvård',
        mainEntity: faqs.map((faq) => ({
          '@type': 'Question',
          name: faq.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: faq.answer,
          },
        })),
      },
    });
  }

  public generateForProducts(
    id: string | number,
    products: IShopifyProduct[]
  ): void {
    this.updatedSubject.next({
      id,
      data: {
        '@context': this.context,
        '@type': 'ItemList',
        name: 'Products',
        itemListElement: products.map((product) => ({
          '@type': 'ListItem',
          name: product.title,
          position: products.indexOf(product) + 1,
          url: this.getProductUrl(product),
        })),
      },
    });
  }

  public generateForProductWithVariants(
    id: string | number,
    product: IShopifyProduct
  ): void {
    const productGroup = {
      '@context': this.context,
      '@type': 'ProductGroup',
      name: product.title,
      description: product.body_html,
      url: this.getProductUrl(product),
      brand: {
        '@type': 'Brand',
        name: product.vendor,
      },
      audience: {
        '@type': 'PeopleAudience',
        suggestedGender: 'unisex',
      },
      variesBy: product.options
        .map((opt) => {
          const type = this.getOptionType(opt);
          return type ? `https://schema.org/${type}` : type;
        })
        .filter((v) => !!v),
      hasVariant: product.variants.map((variant) => {
        const productData = this.createProduct(
          product,
          variant,
          `${product.title} ${variant.title}`
        );
        product.options.forEach((opt) => {
          const type = this.getOptionType(opt);
          if (type) {
            const value = variant[`option${opt.position}`];
            (productData as any)[type] = value;
            productData.url += `${opt.position === 1 ? '?' : '&'}${
              opt.name
            }=${value}`;
          }
        });
        return productData;
      }),
    } as WithContext<ProductGroup>;
    this.updatedSubject.next({
      data: [
        productGroup,
        this.shippingDetails,
        this.returnPolicy,
      ] as WithContext<
        ProductGroup | MerchantReturnPolicy | OfferShippingDetails
      >[],
      id,
    });
  }

  private createProduct(
    product: IShopifyProduct,
    variant: IShopifyProductVariant,
    name?: string
  ): WithContext<Product> {
    const images = product.images
      .sort((i) =>
        i.id === variant.image_id ? -1 : i.id === product.image.id ? 0 : 1
      )
      .map((i) => i.src);
    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      sku: variant.sku,
      gtin13: variant.barcode ?? undefined,
      image: images,
      name: name ?? product.title,
      description: product.body_html,
      url: this.getProductUrl(product),
      offers: this.productOffer(variant),
      brand: {
        '@type': 'Brand',
        name: product.vendor,
      },
    };
  }

  private getProductAvaiability(
    variant: IShopifyProductVariant
  ): ItemAvailability {
    return variant.inventory_quantity > 0
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock';
  }

  private getOptionType(opt: IShopifyProductOption): string | undefined {
    switch (opt.name.toLowerCase()) {
      case 'storlek':
        return 'size';
      case 'färg':
        return 'color';
      default:
        return undefined;
    }
  }
  private get shippingDetails(): WithContext<OfferShippingDetails> {
    return {
      '@context': this.context,
      '@type': 'OfferShippingDetails',
      '@id': '#shipping_policy',
      shippingRate: {
        '@type': 'MonetaryAmount',
        value: 49,
        currency: 'SEK',
      },
      url: `${environment.apiTarget}/villkor/shipping-policy`,
      shippingDestination: {
        '@type': 'DefinedRegion',
        addressCountry: 'SE',
      },
      deliveryTime: {
        '@type': 'ShippingDeliveryTime',
        handlingTime: {
          '@type': 'QuantitativeValue',
          minValue: 0,
          maxValue: 1,
          unitCode: 'DAY',
        },
        transitTime: {
          '@type': 'QuantitativeValue',
          minValue: 1,
          maxValue: 2,
          unitCode: 'DAY',
        },
      },
    };
  }
  private get returnPolicy(): WithContext<MerchantReturnPolicy> {
    return {
      '@context': this.context,
      '@type': 'MerchantReturnPolicy',
      '@id': '#return_policy',
      applicableCountry: 'SE',
      returnPolicyCategory:
        'https://schema.org/MerchantReturnFiniteReturnWindow',
      merchantReturnDays: 30,
      returnMethod: 'https://schema.org/ReturnByMail',
      returnFees: 'https://schema.org/ReturnFeesCustomerResponsibility',
      url: `${environment.apiTarget}/villkor/refund-policy`,
    };
  }

  public generateForProduct(
    id: string | number,
    product: IShopifyProduct,
    variant: IShopifyProductVariant
  ): void {
    this.updatedSubject.next({
      data: [
        this.createProduct(product, variant),
        this.returnPolicy,
        this.shippingDetails,
      ],
      id,
    });
  }

  private productOffer(variant: IShopifyProductVariant): Offer {
    const originalPrice =
      variant.compare_at_price && +variant.price < +variant.compare_at_price
        ? variant.compare_at_price
        : variant.price;
    const offer: Offer = {
      '@type': 'Offer',
      priceSpecification: [
        {
          '@type': 'UnitPriceSpecification',
          priceType: 'https://schema.org/ListPrice',
          priceCurrency: this.currencyCode,
          price: +originalPrice,
        },
      ],
      itemCondition: 'https://schema.org/NewCondition',
      availability: this.getProductAvaiability(variant),
      shippingDetails: { '@id': '#shipping_policy' },
      hasMerchantReturnPolicy: { '@id': '#return_policy' },
    };

    if (variant.compare_at_price === originalPrice) {
      (offer.priceSpecification as PriceSpecification[]).push({
        '@type': 'UnitPriceSpecification',
        priceCurrency: this.currencyCode,
        price: +variant.price,
      });
    }

    if (variant.sku && variant.sku !== '') {
      offer[
        'checkoutPageURLTemplate'
      ] = `${environment.apiTarget}/kassa?sku={id}`;
    }

    return offer;
  }

  public generateForCourses(id: string | number, courses: ICourse[]): void {
    this.updatedSubject.next({
      id,
      data: {
        '@context': this.context,
        '@type': 'ItemList',
        name: 'Fotverkets Kurser',
        itemListElement: courses.map((course) => ({
          '@type': 'ListItem',
          position: courses.indexOf(course) + 1,
          name: course.name,
          url: course.url,
          image: course.thumbnail,
          description: course.subtitle,
        })),
      },
    });
  }
  private getProductUrl(product: IShopifyProduct): string {
    return `${environment.apiTarget}/produkter/${product.handle}`;
  }
}
