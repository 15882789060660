import { Injectable, OnInit } from '@angular/core';
import { AppInsightsService } from '@core/services/app-insights.service';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { BaseComponent } from '@standalone/base-component.component';
import { takeUntil } from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class CookieManagementService extends BaseComponent {
  constructor(
    private cookieConsentService: CookieConsentService,
    private appInsightsService: AppInsightsService,
    private loggingService: LoggingService
  ) {
    super();
    this.cookieConsentService.cookie$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (cookie) => {
          this.enableFunctionalCookies(cookie?.functional ?? false);
          this.enableStatisticalCookies(cookie?.statistical ?? false);
          this.enableMarketingCookies(cookie?.marketing ?? false);
          this.enableNecessaryCookies(cookie?.necessary ?? false);
        },
      });
  }
  private enableStatisticalCookies(value: boolean): void {
    this.appInsightsService.enableCookies(value);
    if (!value) {
      try {
        var pjpixel: any;
        pjpixel?.clear();
      } catch (error) {
        if (error instanceof Error) {
          this.loggingService.logException(error);
        }
      }
    }
  }

  private enableFunctionalCookies(value: boolean): void {}
  private enableMarketingCookies(value: boolean): void {}
  private enableNecessaryCookies(value: boolean): void {}
}
