import { MetaDefinition } from '@angular/platform-browser';
import { Data } from '@angular/router';

export interface IContactInfo {
  name: string;
  companyName: string;
  streetAddress: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
  orgNr: string;
}

export interface IFaq {
  question: string;
  answer: string;
}

export interface IService {
  name: string;
  price: number;
  vat: number;
  priceSuffix?: string;
  linkItem: ILinkItem;
  description: string;
}

export interface ILinkItem {
  link: string;
  text: string;
}

export interface IEnvironment {
  environment: string;
  isTest: boolean;
  apiTarget: string;
  apiKey: string;
  saveContactInformation: boolean;
  hostName: string;
  googleMaps: string;
  appInsights: {
    config: {
      instrumentationKey: string;
    };
    roleName: string;
  };
  contactForm: {
    baseUrl: string;
    endpoint: string;
    subject: string;
    redirectToPage: string;
  };
  logging: {
    enabled: boolean;
    consoleEnabled: boolean;
    debug: boolean;
  };
  metaTags: MetaDefinition[];
  contactInfo: IContactInfo;
  services: IService[];
  cookies: {
    cookieName: string;
    cartCookieName: string;
  };
  useClarity: boolean;
  googleTagManagerId: string;
}

export interface IlandingPageText {
  header: string;
  text: string;
}

export interface IFotverketData extends Data {
  title: string;
  description: string;
  name?: string;
  pageTitle?: string;
  skip?: boolean;
  index?: boolean;
}

export enum Environment {
  Dev = 'dev',
  Test = 'test',
  Prod = 'production',
}
