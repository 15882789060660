import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@src/environments/environment';
import { EventType, LoggingService } from '@core/services/logging.service';
import { FormHelperService } from '@core/services/form-helper.service';
import { CookieService } from '@core/services/cookie.service';
import { BaseComponent } from '@standalone/base-component.component';
import { LanguageShortName } from 'shared/TranslationLoader';
import { CookieManagementService } from './cookie-management.service';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService extends BaseComponent {
  private cookieName: string = environment.cookies.cookieName;
  private cookieSubject: BehaviorSubject<IFotverketCookie | null> =
    new BehaviorSubject<IFotverketCookie | null>(null);
  constructor(
    private cookieService: CookieService,
    private loggingService: LoggingService,
    private formHelperService: FormHelperService
  ) {
    super();
    this.getCookie();
  }

  public get cookieForm(): UntypedFormGroup {
    let cookie: IFotverketCookie | undefined =
      this.cookieService.tryGet<IFotverketCookie>(this.cookieName);
    if (!cookie) {
      cookie = {
        functional: false,
        marketing: false,
        necessary: true,
        statistical: false,
      } as IFotverketCookie;
    }

    const validationSchema = {
      functional: [[Validators.required]],
      marketing: [[Validators.required]],
      necessary: [[Validators.required, Validators.requiredTrue]],
      statistical: [[Validators.required]],
    };

    return this.formHelperService.parseData(
      cookie,
      validationSchema
    ) as UntypedFormGroup;
  }

  private parseCookie(cookie: IFotverketCookie | null): void {
    this.cookieSubject.next(cookie);
  }

  private getCookie(): void {
    const cookie = this.cookieService.tryGet<IFotverketCookie>(this.cookieName);
    if (cookie) {
      this.parseCookie(cookie);
    }
  }

  public get cookie$(): Observable<IFotverketCookie | null> {
    return this.cookieSubject.asObservable();
  }

  public setCookie(cookie: IFotverketCookie): void {
    this.parseCookie(cookie);
    const currentCookie = this.cookieService.tryGet<IFotverketCookie>(
      this.cookieName
    );

    if (currentCookie) {
      currentCookie.functional = cookie.functional;
      currentCookie.marketing = cookie.marketing;
      currentCookie.statistical = cookie.statistical;
      currentCookie.necessary = cookie.necessary;
      if (!!cookie.language) {
        currentCookie.language = cookie.language;
      }
    }
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    this.cookieService.set(
      this.cookieName,
      JSON.stringify(currentCookie || cookie),
      aYearFromNow,
      '/'
    );
  }

  public removeCookies(): void {
    this.cookieService.deleteAll();
    this.cookieSubject.next(null);
  }
}

export interface IFotverketCookie {
  necessary: boolean;
  functional: boolean;
  statistical: boolean;
  marketing: boolean;
  language: LanguageShortName;
}
