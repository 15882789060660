import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { combineLatestWith, filter, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@standalone/base-component.component';
import { LoadingService } from '@features/loading/services/loading.service';
import { IFotverketData } from '@environment/settings-interface';
import { ProductService } from './product.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends BaseComponent {
  public swipingSubject = new BehaviorSubject<boolean>(false);
  public navigationStartSubject = new BehaviorSubject<NavigationStart | null>(
    null
  );
  public navigationEndSubject = new BehaviorSubject<NavigationEnd | null>(null);
  private history: string[] = [];
  public routeDataSubject = new Subject<Data>();
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private location: Location,
    private route: ActivatedRoute,
    private productService: ProductService,
    private gtmService: GoogleTagManagerService
  ) {
    super();
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(
          this.productService.ready$.pipe(
            takeUntil(this.ngUnsubscribe),
            filter((r) => r)
          )
        ),
        map((v) => v[0])
      )
      .subscribe({
        next: (event) => {
          if (event instanceof NavigationStart) {
            this.loadingService.stopLoading();
            this.navigationStartSubject.next(event);
          } else if (event instanceof NavigationEnd) {
            this.sendData(this.getDataForCurrentRoute());
            this.history.push(event.urlAfterRedirects);
            this.navigationEndSubject.next(event);
            const gtmTag = {
              event: 'page',
              pageName: event.url,
            };
            this.gtmService.pushTag(gtmTag);
          }
        },
      });
  }

  private getDataForCurrentRoute() {
    let route: ActivatedRouteSnapshot | null = null;
    let next: ActivatedRouteSnapshot | null = this.route.snapshot;
    let handle: string | undefined = undefined;
    while (next) {
      route = next;
      next = next.firstChild;
      if (!route?.url[0]) {
        continue;
      }
      handle = route.url[0].path;
    }

    let data = route?.data ?? {};
    if (data['name'] === 'ProductDetails' && handle) {
      data = this.getProductData(handle);
    }

    return data;
  }

  private getProductData(handle: string): Data {
    const product = this.productService.getProductByHandle(handle);
    const title = product?.title ?? handle;
    return {
      name: title,
      title: title,
      description: product?.body_html,
    } as IFotverketData;
  }

  public back(): void {
    const url = this.history.pop();
    const route = url?.substring(0, url.lastIndexOf('/'));
    if (this.history.length > 0) {
      this.location.back();
    } else if (route) {
      this.router.navigate(route.split('/'));
    } else {
      this.router.navigate(['']);
    }
  }

  public sendData(data: Data): void {
    if (!data) {
      return;
    }

    this.routeDataSubject.next(data);
  }

  public get routeData$(): Observable<Data> {
    return this.routeDataSubject.asObservable();
  }

  public get swiping$(): Observable<boolean> {
    return this.swipingSubject.asObservable();
  }

  public get navigationStart$(): Observable<NavigationStart | null> {
    return this.navigationStartSubject.asObservable();
  }

  public get navigationEnd$(): Observable<NavigationEnd | null> {
    return this.navigationEndSubject.asObservable();
  }
}
